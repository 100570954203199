import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'

export default function LineOfTime({ time }) {
    const [linePosition, setLinePosition] = useState(0)

    const updateLinePosition = () => {
        const { startDate, endDate } = time

        const HALF_AN_HOUR = 30
        const PIXEL_FOR_HOUR = 60
        const MINUTES_IN_HOUR = 60
        const MARGIN = 10
        const BORDER_HEIGHT = 1
        const LINE_HEIGHT = 2
        const EXTRA_SPACE = MARGIN + BORDER_HEIGHT + LINE_HEIGHT

        const dateNow = moment()
        const progressTime = dateNow.isAfter(endDate)
            ? endDate.diff(startDate, 'minutes')
            : dateNow.diff(startDate, 'minutes')

        if (progressTime >= HALF_AN_HOUR) {
            setLinePosition((PIXEL_FOR_HOUR / MINUTES_IN_HOUR) * progressTime + EXTRA_SPACE)
        }
    }

    useEffect(() => {
        updateLinePosition()

        const ONE_MINUTE = 60000
        const interval = setInterval(updateLinePosition, ONE_MINUTE)
        return () => {
            clearInterval(interval)
        }
    }, [time])

    return <View style={[localStyles.container, { top: linePosition }]}></View>
}

const localStyles = StyleSheet.create({
    container: {
        height: 2,
        backgroundColor: 'red',
        flex: 1,
        marginHorizontal: 8,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
    },
})
